const DataUrlSize = (dataUrl) => {
  if (!dataUrl) {
    return 0; // Return 0 if the data URL is empty or invalid
  }

  // Calculate the size in bytes
  const byteString = atob(dataUrl.split(',')[1]); // Decode base64 string
  const sizeInBytes = byteString.length; // Length of the decoded string gives the size in bytes

  // Convert bytes to kilobytes
  const sizeInKB = (sizeInBytes / 1024).toFixed(2);

  console.log("DataUrlSize:", sizeInKB, "KB");
  // Return the size in kilobytes with two decimal places
  return sizeInKB;
};

export default DataUrlSize;
