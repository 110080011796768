import React, { useState, useContext, useRef } from "react";
import goodnews from "../../../assets/img/TCG_L_D/goodnews.jpg";
import CheckBox from "../../UI/CheckBox";
import loader03 from "../../../assets/img/TCG_L_D/loader03.svg";
import Button from "../../UI/Button";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import axios from "axios";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import "../../../assets/css/TCG_L_C/authorise.scss";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useAuthorizeStatus } from "../../../Hooks/useAuthorizeStatus";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";

const AuthoriseC3 = (props) => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_C_3",
    "v5/authorise"
  );
  const [loader, setLoader] = useState("hide");
  const [tcgurl, setTcgurl] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [checkAuthoriseValidation, setCheckAuthoriseValidation] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveAuthorizeStatus } = useAuthorizeStatus();
  const query = new URLSearchParams(window.location.search);
  const split_name = query.get("splitName");
  const brand = getBrandName();
  const history = useHistory();
  const authoriseValidationRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    if (checkAuthoriseValidation === false) {
      authoriseValidationRef.current.focus();
      authoriseValidationRef.current.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      });
      setValidationError(true);
    } else {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'Authorise',
        answer: '',
      });
      setTimeout(() => {
        authrizeSubmit();
      }, 2000);
    }
  };
  const viewtcgdoc = async (e) => {
    e.preventDefault();
    try {
      const url = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };
  const authrizeSubmit = async () => {
    await saveAuthorizeStatus(visitorParameters.visitor_parameters.uuid);
    history.push(
      "/" +
      DynamicRouteNextPage +
      "?uuid=" +
      visitorParameters.visitor_parameters.uuid +
      "&splitName=" +
      split_name
    );
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="authorise TCG_L_D">
        <div
          className={`modal bg-dark fade show`}
          id="signConfirm"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header py-3 text-center bg-pophead text-center justify-content-center">
                <LogoBasedOnDomain height="30" bg="" />
              </div>
              <div className="modal-body  creditpopup signpopip">
                {loader == "hide" ? (
                  <div className="signOne relative">
                    <div className="text-center mb-4">
                      <img height={100} src={goodnews} alt="" />
                    </div>
                    <p className="mb-0 pb-2">
                      We are ready to assist you further with your complaint.
                    </p>
                    <div
                      className="form-check check_box_agree d-flex"
                      id="signaturetickbox"
                    >
                      <div>
                        <CheckBox
                          type="checkbox"
                          className="form-check-input display-block"
                          defaultValue=""
                          onChange={() => { setCheckAuthoriseValidation(!checkAuthoriseValidation); setValidationError(false) }}
                          checked={checkAuthoriseValidation}
                        />
                      </div>

                      <div className="ps-3">
                        <label className="form-check-label" htmlFor="flexCheck1">
                        {/* I have read the <a href={tcgurl} target="_blank" onClick={viewtcgdoc}>car finance claims documents</a>, including the Client Care letter, the Terms of Engagement and Letter of Authority, and the statements below and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Form for each lender and a Letter of Authority for the DVLA */}
                        I have read the car finance claim documents, including the Client Care Letter,Terms of Engagement and Letter of Authority, and the statements above and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Declaration for each lender.
                        </label>
                      </div>
                    </div>
                    <p className="lh-base ">
                      {" "}
                      <span>
                        Please note that {brand} is a trading style of The
                        Claims Guys Legal, who will handle and process your claim.{" "}
                      </span>
                    </p>
                    <p>
                              <span className="sml-note">
                                Please carefully read the statements below  before you select I Authorise below.
                              </span>
                            </p>
                           
                    {/* <div className="border border-primary p-2 mb-4 border-3 text-center">
                      <p className="mb-0 pb-0 fw-bold">
                        {" "}
                        <a
                          target="_blank"
                          href=""
                          onClick={viewtcgdoc}
                        >
                          Car finance claims documents
                        </a>
                      </p>
                    </div> */}
                    <div ref={authoriseValidationRef}>
                      {validationError && (
                        <span className="error_msg">
                          Please tick to Proceed
                        </span>
                      )}
                    </div>
                    
                    <ul className="list-itemsc ps-0 ms-0">
                      <li>
                        {" "}
                        I was not fully informed about the commission the above lenders, and their grouped lenders, paid to the car dealerships who arranged my car finance. I authorise The Claims Guy Legal to make claims on my behalf about all car finance agreements I held.
                      </li>
                      <li>
                        I authorise The Claims Guys Legal to:
                        <ul className="mt-2 list-itemsb">
                          <li className="pb-2">
                            {" "}
                            raise an information request to the lenders identified to request information to support my claim, including Vehicle and Credit Agreement details, and information about any add-on products. I agree for this information to be released to The Claims Guys Legal; and{" "}
                          </li>
                          <li className="pb-2">
                            {" "}
                            refer my claim to the Financial Ombudsman Service (FOS), where a satisfactory outcome is not provided; and
                          </li>
                          <li className="pb-2">
                            receive payment of any compensation due on my behalf.
                            {" "}
                          </li>
                        </ul>
                      </li>
                      <li>
                        I further authorise The Claims Guys Legal to access my credit report through Checkboard Limited, Valid 8 Ltd or another such provider. I understand that by continuing this application, I agree to Checkboard Limited’s Privacy Policy and the Terms and Conditions. I understand that this will not affect my credit score. I agree for my credit report to be used to:
                        <ul className="mt-2 list-itemsb">
                          <li>
                            confirm the lenders that I held car finance with; and{" "}
                          </li>
                          <li>
                            provide evidence to support my claims.{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>When you click <b>I Authorise</b> The Claims Guys Legal will receive the personal information you have provided and will start processing your claim.</p>
                    <div className="sticky">
                      <Button
                        className=" nextbtn2  p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold  pulse2  "
                        id="signOne"
                        onClick={(e) => handleClick(e)}
                        spanText="bi bi-arrow-right"
                        buttonText="I Authorise"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={
                      loader === "show"
                        ? { display: "block" }
                        : { display: "block" }
                    }
                    className="loadersign"
                  >
                    <div className=" pt-5">
                      <div className=" text-center ">
                        <div className="pt-5">
                          <div>
                            {" "}
                            <img height={100} src={loader03} alt="" />
                          </div>
                          <div>Loading...</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthoriseC3;
