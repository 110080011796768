import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect"; 
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../../UI/InputButton";
import { useSignature } from "../../../Hooks/useSignature";
import { signCheckText } from "../../../Constants/Constants";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Button from "../../UI/Button";
import "../../../assets/Signature/TCG_L_A/css/signature.scss";
import Footer from "../../Includes/Layouts/TCG_L_A/Footer";
import Header from "../../Includes/Layouts/TCG_L_A/Header";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import financePdf from "../../../assets/pdf/car_finance_claims_documents.pdf"
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import * as EnvConstants from "../../../Constants/EnvConstants";
import axios from "axios";
import DataUrlSize from "../../../Utility/DataUrlSize";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const SignatureV1 = ({ title, firstName, middleName, lastName }) => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_A",
    "v1/signature"
  );

  const { isCheck } = CheckUUID();
  isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const focusRef = useRef(null);
  const signPadRef = useRef();
  const errorMsgRef = useRef(null);

  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const { saveSignature, isLoading } = useSignature();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const storedFormData = JSON.parse(localStorage.getItem("formData"));
  const [isCheckedFocus, setIsCheckedFocus] = useState(false);
  const [buttonText, setButtonText] = useState("Submit And Proceed");
  const [tcgurl, setTcgurl] = useState('');
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const [show1, setShow1] = useState(true);
  const [loaderStatus, setLoaderStatus] = useState(true);

  // const split_name = query.get("split_name");
  const split_name = "pcp/TCG_L_A";
  const message_type = "signature_store";
  const brandName = getBrandName();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const handleSubmit = async () => {
    try {
      if (!isChecked) {
        focusRef.current.focus();
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.focus();
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }


      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      const signatureData = signPad.getTrimmedCanvas().toDataURL('image/png');
      const sizeInKB = DataUrlSize(signatureData);

      if (signLength > 13 && sizeInKB > 2) {
        setBtnLoader(true);
        const response = await saveSignature(
          signatureData,
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (split_name === "pcp/TCG_L_A") {
          GTMDataLayer({
            clickEvent: 'slideclick',
            question: 'signature',
            answer: '',
          });
          history.push(
            "/" +
              DynamicRouteNextPage +
              "?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&splitName=" +
              split_name
          );
        }
        if (response.data.status === "Success") {
          setBtnLoader(false);

          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signatureData;
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
        }
      } else {
        // focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
        handleClear()
      }
    } catch (error) {
      setBtnLoader(false);
      console.error("Error submitting signature:", error);
    }
  };

  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    return dispatch({ type: "privacyAgreed", payload: { value: false } });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!showLoader) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [showLoader]);
  

  useEffect(() => {
    if (isChecked) {
      // focusRef.current.focus();
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);


  const focusOnCheckbox = () => {
      const canvas = focusRef.current;
      if (canvas) {
        canvas.tabIndex = 0;
        canvas.focus();
      }
    setIsCheckedFocus(true);
  };
  const viewtcgdoc = async (e) => {
    e.preventDefault();

    try {
      const url  = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };

  return (
    <>
      <GetVisitorsParams />
      {/* {loaderStatus && ( */}
      <div className="TheClaimsGuys_ver_A">
        {showLoader && (
          <div id="preloader">
            <div className="mt-3" id="loader"></div>
            <h3>Analysing Your Results...</h3>
          </div>
        )}
        <Header />
          <section className="signature">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-12">
                  <form className="signwrapper">
                    <h2>Your Signature</h2>
                    <p>
                      {brandName} is a trading style of The Claims Guys Legal,
                      who will handle and process your claim.
                    </p>
                    <p>
                      Please carefully read the car{" "}
                      <a href={tcgurl}  target="_blank" onClick={viewtcgdoc}>finance claims documents</a> and statements
                      below before you add your signature.
                    </p>
                    <ul className="ul_class">
                    <li className="li_class">
                      I was not fully informed about the commission the above lenders, and their grouped lenders, paid to the car dealerships who arranged my car finance. I authorise The Claims Guy Legal to make claims on my behalf about all car finance agreements I held.
                    </li>
                    <li className="li_class">
                      I authorise The Claims Guys Legal to:
                      <ul className="sub_ul mt-2">
                        <li className="sub_li">
                          raise an information request to the lenders identified to request information to support my claim, including Vehicle and Credit Agreement details, and information about any add-on products. I agree for this information to be released to The Claims Guys Legal; and
                        </li>
                        <li className="sub_li">
                          refer my claim to the Financial Ombudsman Service (FOS), where a satisfactory outcome is not provided; and
                        </li>
                        <li className="sub_li">
                          receive payment of any compensation due on my behalf.
                        </li>
                        </ul>
                      </li>
                    <li className="li_class">
                      I further authorise The Claims Guys Legal to access my credit report through Checkboard Limited, Valid 8 Ltd or another such provider. I understand that by continuing this application, I agree to Checkboard Limited’s Privacy Policy and the Terms and Conditions. I understand that this will not affect my credit score. I agree for my credit report to be used to:
                      <ul className="sub_ul mt-2">
                        <li className="sub_li">
                          confirm the lenders that I held car finance with; and
                        </li>
                        <li className="sub_li">
                          provide evidence to support my claims.
                        </li>
                      </ul>
                    </li>
                    </ul>
                    <span ref={focusRef} tabIndex={0}>
                    <div className="d-flex align-item-top sign-check mb-3 mt-4" id="checkbox11">
                      <div>
                        <CheckBox
                          type="checkbox"
                          className={`checkinput me-2 pulse cls-opcty ${state.isAgreed ? "blink_red" : isCheckedFocus && !isChecked ? "blink_green" : ""}`}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div>
                        <label for="checkbox1"
                          className="text-start"> I have read the car finance claim documents, including the Client Care Letter,Terms of Engagement and Letter of Authority, and the statements above and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Declaration for each lender.
                        </label>
                      </div>
                    </div>
                    {state.isAgreed && (
                      <span className="error_msg">
                        Please tick to accept in order to proceed
                      </span>
                    )}
                    <div className="sign-pad">
                      <h5>
                        Sign below <i className="bi bi-arrow-down icodown"></i>
                      </h5>
                      <div className="sign-pad">
                        {(deviceType === "mobile" ||
                          deviceType === "tablet") && (
                          <MobileView>
                            <SignatureCanvas
                              canvasProps={{
                                className: "writing-line",
                                id: "canvas_signature",
                                width: 300,
                                height: 350
                              }}
                              clearOnResize={false}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  setButtonText("Submit And Proceed ");
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                          </MobileView>
                        )}
                        {deviceType === "browser" && (
                          <BrowserView>
                            <SignatureCanvas
                              canvasProps={{
                                className: "writing-line",
                                id: "canvas_signature"
                              }}
                              clearOnResize={false}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  setButtonText("Submit And Proceed");
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                          </BrowserView>
                        )}
                      </div>

                      {state.isCanvasEmpty && (
                        <div
                          className="sign_msg col-12 text-center sign_msg signature-validation"
                          style={{
                            backgroundColor: "red",
                            borderRadius: "5px",
                          }}
                          id="signatures_required"
                     
                        >
                          Signature is required!
                        </div>
                      )}
                      {state.isValid && (
                        <div
                          className="sign_msg col-12 text-center sign_msg signature-validation"
                          style={{ backgroundColor: "red" }}
                          id="signatures_valid"
                        >
                          Draw valid signature!
                        </div>
                      )}
                      <InputButton
                        name="clear"
                        className="clearButton"
                        id="clear"
                        value="Clear"
                        btnType="button"
                        onClick={handleClear}
                      />
                    </div>
                    </span>
                    <p>
                      When you click Submit And Proceed we will receive the personal
                      information you have provided and will start processing
                      your claim.
                    </p>
                    <div
                      id="signbutmain"
                      className="autoUpdate text-end "
                      style={{ display: !isChecked && !isCheckedFocus ? "block" : "none" }}
                    >
                    
                       <InputButton
                        name="save"
                        className={`signButton fixed_but but_anim w-100`}
                        id="save"
                        value={buttonText}
                        btnType="button"
                        onClick={focusOnCheckbox}
                      />
                    </div>
                    <div
                      id="signbutmain"
                      className="autoUpdate text-end"
                      style={{ display: isChecked || isCheckedFocus ? "block" : "none" }}
                    >
                      <InputButton
                        name="save"
                        className={`signButton fixed_but but_anim w-100`}
                        id="save"
                        value={buttonText}
                        btnType="button"
                        onClick={!btnLoader ? handleSubmit : null}
                      />
                    </div>
               
                  </form>
                </div>
              </div>
            </div>
          </section>
        
        <Footer />
      </div>

      
    </>
  );
};
export default SignatureV1;
