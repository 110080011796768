import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../../UI/InputButton";
import { useSignature } from "../../../Hooks/useSignature";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Header from "../../Includes/Layouts/TCG_L_B/Header";
import Footer from "../../Includes/Layouts/TCG_L_B/Footer";
import GetLenders from "../../../Utility/GetLenders";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import Loader from "../../Includes/Layouts/Common/Loader";
import financePdf from "../../../assets/pdf/car_finance_claims_documents.pdf"
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import * as EnvConstants from "../../../Constants/EnvConstants";
import axios from "axios";
import DataUrlSize from "../../../Utility/DataUrlSize";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureV3 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_B",
    "v2/signature"
  );

  // const { isCheck } = CheckUUID();
  // isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const split_name = "pcp/TCG_L_B";
  const storedFormData = JSON.parse(localStorage.getItem("formData") || "{}");
  storedFormData.page_name = split_name;
  const [btnLoader, setBtnLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { saveSignature, isLoading } = useSignature();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const [isCheckedFocus, setIsCheckedFocus] = useState(false);
  const message_type = "signature_store";
  const history = useHistory();
  const { getLenderList } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [buttonText, setButtonText] = useState("Submit And Proceed");
  const brandName = getBrandName();
  const [tcgurl, setTcgurl] = useState('');
  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };
  const focusRef = useRef(null);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    return dispatch({ type: "privacyAgreed", payload: { value: false } });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000);
    (async () => {
      const response = await getLenderList();
    })();
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = async () => {
    try {
      if (!isChecked) {
        focusRef.current.focus();
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.focus();
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }

      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      const signatureData = signPad.getTrimmedCanvas().toDataURL('image/png');
      const sizeInKB = DataUrlSize(signatureData);

      if (signLength > 13 && sizeInKB > 2) {
        setBtnLoader(true);
        const response = await saveSignature(
          signatureData,
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type
        );
   
        if (split_name === "pcp/TCG_L_B") {

          GTMDataLayer({
            clickEvent: 'slideclick',
            question: 'signature',
            answer: '',
          });

          history.push(
            "/" +
              DynamicRouteNextPage +
              "?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&splitName=" +
              split_name
          );
        }

        if (response.data.status === "Success") {
          setBtnLoader(false);

          const formdata = JSON.parse(localStorage.getItem("formData"))
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signatureData;
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
       
        }
      } else {
        focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
        handleClear()
      }
    } catch (error) {
      setBtnLoader(false);
      console.error("Error submitting signature:", error);
    }
    
  };

  const focusOnCheckbox = () => {
    if (signPadRef.current) {
      signPadRef.current.off();
      const canvas = signPadRef.current.getCanvas();
      if (canvas) {
        canvas.tabIndex = 0;
        canvas.focus();
      }
      signPadRef.current.on();
    }
    setIsCheckedFocus(true);
  };
  const viewtcgdoc = async (e) => {
    e.preventDefault();

    try {
      const url  = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="TCG_L_B">
        {loaderStatus && <Loader loderContent={`Analysing Your Results...`} />}
        {!loaderStatus && (
          <div className="wrapper w-100">
            <section className="header-wrap text-center">
              <Header />
            </section>
            <section className="dashboad-bottom  ">
              <div className="inner  pt-3 pb-5">
                <div className="container all-slides">
                  <div className="sign-sec">
                    <h3 className="text-center title">
                      {brandName} is a trading style of The Claims Guys Legal,
                      who will handle and process your claim.
                    </h3>
                    <p className=" mb-3 mt-4 fw-bold">
                      Please carefully read the{" "}
                      <a href={tcgurl}  target="_blank" onClick={viewtcgdoc}>car finance claims documents</a> and statements
                      below before you add your signature.
                    </p>

                    <ul className="list-point ps-0">
                      <li className="li_class">
                        I was not fully informed about the commission the above lenders, and their grouped lenders, paid to the car dealerships who arranged my car finance. I authorise The Claims Guy Legal to make claims on my behalf about all car finance agreements I held.
                      </li>
                      <li className="li_class">
                        {" "}
                        I authorise The Claims Guys Legal to:
                        <ul className="sub_ul mt-2">
                          <li className="sub_li">
                            raise an information request to the lenders identified to request information to support my claim, including Vehicle and Credit Agreement details, and information about any add-on products. I agree for this information to be released to The Claims Guys Legal; and
                          </li>
                          <li className="sub_li">
                            refer my claim to the Financial Ombudsman Service (FOS), where a satisfactory outcome is not provided; and
                          </li>
                          <li className="sub_li">
                            receive payment of any compensation due on my behalf.
                          </li>

                        </ul>
                      </li>
                      <li className="li_class">
                        {" "}
                        I further authorise The Claims Guys Legal to access my credit report through Checkboard Limited, Valid 8 Ltd or another such provider. I understand that by continuing this application, I agree to Checkboard Limited’s Privacy Policy and the Terms and Conditions. I understand that this will not affect my credit score. I agree for my credit report to be used to: 
                        <ul className="sub_ul mt-2">
                          <li className="sub_li">
                            confirm the lenders that I held car finance with; and
                          </li>
                          <li className="sub_li">
                            provide evidence to support my claims.
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <div
                      className="d-flex align-item-top sign-check mb-3 mt-4"
                      id="checkbox11"
                    >
                      <div>
                        {/* <input type="checkbox" id="checkbox1" className="checkinput me-2 pulse "  /> */}
                        <CheckBox
                          className={`checkinput me-2 pulse ${
                            state.isAgreed
                              ? "blink_red"
                              : isCheckedFocus && !isChecked
                              ? "blink_green"
                              : ""
                          }`}
                          type="checkbox"
                          value=""
                          id="checkbox1"
                          // checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div>
                        <label for="checkbox1">
                          I have read the car finance claim documents, including the Client Care Letter,Terms of Engagement and Letter of Authority, and the statements above and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Declaration for each lender.
                        </label>
                      </div>
                    </div>
                    {state.isAgreed && (
                      <span className="error_msg">
                        Please tick to accept in order to proceed
                      </span>
                    )}

                    {/* <div className="signbox"  id="signBox">
            <canvas className=""> </canvas>

          </div> */}

                    <div className="signbox">
                      {(deviceType === "mobile" || deviceType === "tablet") && (
                        <MobileView>
                          <SignatureCanvas
                            canvasProps={{
                              className: "writing-line",
                              id: "canvas_signature",
                              height: 350,
                              width: window.innerWidth - 20,
                            }}
                            clearOnResize={false}
                            ref={signPadRef}
                            onEnd={() => {
                              if (!signPadRef.current.isEmpty()) {
                                setButtonText("Submit And Proceed");
                                dispatch({
                                  type: "signPadChange",
                                  payload: { value: false },
                                });
                                dispatch({
                                  type: "validSignature",
                                  payload: { value: false },
                                });
                              }
                            }}
                          />
                        </MobileView>
                      )}

                      {deviceType === "browser" && (
                        <BrowserView>
                          <SignatureCanvas
                            canvasProps={{
                              className: "writing-line",
                              id: "canvas_signature",
                              height: 350,
                              width: 1124,
                            }}
                            clearOnResize={false}
                            ref={signPadRef}
                            onEnd={() => {
                              if (!signPadRef.current.isEmpty()) {
                                dispatch({
                                  type: "signPadChange",
                                  payload: { value: false },
                                });
                                dispatch({
                                  type: "validSignature",
                                  payload: { value: false },
                                });
                              }
                            }}
                          />
                        </BrowserView>
                      )}
                    </div>
                    {state.isCanvasEmpty && (
                      <div
                        className="sign_msg col-12 text-center sign_msg signature-validation"
                        style={{
                          backgroundColor: "red",
                          borderRadius: "5px",
                        }}
                        id="signatures_required"
                      >
                        Signature is required!
                      </div>
                    )}
                    {state.isValid && (
                      <div
                        className="sign_msg col-12 text-center sign_msg signature-validation"
                        style={{ backgroundColor: "red" }}
                        id="signatures_valid"
                      >
                        Draw valid signature!
                      </div>
                    )}
                    {/* <button className="clearbutton">Clear</button> */}
                    <InputButton
                      name="clear"
                      className="clearbutton"
                      id="clear"
                      value="Clear"
                      btnType="button"
                      onClick={handleClear}
                      style={{ width: "70px" }}
                    />

                    <div className="d-flex align-item-top sign-check mb-3 mt-4">
                      When you click Submit And Proceed we will receive the personal
                      information you have provided and will start processing
                      your claim.
                    </div>
                    <span ref={focusRef} tabIndex={0}></span>
                    {!isChecked && !isCheckedFocus && (
                      <div className="text-center pb-2   pb-2 btn-sticky ">
                        {/* <button id="clicktoSign"   className="mt-3  pulse btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-1 py-3  " fdprocessedid="dt8vyk">
                <span className="fw-bold"> Click to Sign
                </span>
              </button> */}

                        <input
                          type="button"
                          className="mt-3  pulse btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-1 py-3"
                          id=""
                          value={buttonText}
                          aria-label="submitid"
                          onClick={focusOnCheckbox}
                          name="subRegNo2"
                        />
                      </div>
                    )}
                    {(isChecked || isCheckedFocus) && (
                      <div id="signProceed">
                        {/* <button  className="mt-3  pulse btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-1 py-3  " fdprocessedid="dt8vyk">
                  <span className="fw-bold"> Sign & Proceed
                  </span>
                </button> */}
                        <input
                          type="button"
                          className="mt-3  pulse btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-1 py-3"
                          id="nextPlateSUb_001"
                          value={buttonText}
                          aria-label="submitid"
                          name="subRegNo1"
                          onClick={handleSubmit}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/* <FAQSection faq={Split_TCG_L_B} accordionTitle="FAQ" /> */}
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};
export default SignatureV3;
