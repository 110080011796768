import React, { useContext, useEffect, useRef, useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import { monthMapping, Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import Postcode from "./Postcode";
import { useForm } from "react-hook-form";
import Dob from "./Dob";
import ContactDetails from "./ContactDetails";
import SignatureV4 from "../../../Pages/Signature/SignatureV4";
import Button from "../../../UI/Button";
import {
  nameValidate,
  profanityValidation,
  slideValidation,
} from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import QueryStringContext from "../../../../Contexts/QueryString";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import DataUrlSize from "../../../../Utility/DataUrlSize";

const PersonalDetails = (props) => {
  const [valids, setValids] = useState("");
  const [sign, setSign] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [disable, setDisable] = useState(false);
  const [signatureError, setSignatureError] = useState(false);

  useEffect(() => {}, [sign]);

  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
    const FormSubmit = async (e, signData) => {
        e.preventDefault();
        
        const validationArr = ['lstSalutation', 'txtFName', 'txtLName','txtPostCode','address1', 'DobDay', 'DobMonth', 'DobYear','txtPhone','txtEmail','signature_data'];
        const values = getValues();
        const formData = values;
        formData.page_name = 'pcp/TCG_L_D';
        const surname = { txtLName: values['txtLName'] };
        localStorage.setItem('sur_names', JSON.stringify(surname));
        const dob = new Date(values['DobYear'], monthMapping[values['DobMonth']], values['DobDay']);
        const EndDate = new Date(2000, 5, 1);
        const profanityFields = profanityValidation(['txtFName', 'txtLName'], getValues);
        
        if (profanityFields !== 'success') {
            setError(profanityFields, { message: "Please Enter valid Name" });
            return false;
        }
        
        if (monthMapping[values['DobMonth']] === 1 && values['DobDay'] >= 29) {
            const leapYear = ((values['DobYear'] % 4 === 0) && (values['DobYear'] % 100 !== 0)) || (values['DobYear'] % 400 === 0);
            if ((leapYear && values['DobDay'] > 29) || (!leapYear && values['DobDay'] >= 29)) {
                setValids('Please enter a valid date');
                return;
            } else {
                setValids('');
            }
        }
        
        if (dob >= EndDate) {
            setValids('Please Enter a date before 01/6/2000');
        } else {
            setValids('');
    
            const validationCheck = await slideValidation(validationArr, trigger);
    
            if (validationCheck !== 'Success') {
                return; // Stop the form submission if other validations fail
            }
            const Sign = values['signature_data'];

            if (!Sign || typeof Sign !== 'string' || Sign.trim() === '') {
                setSignatureError(true);
                setError("signature_pad", {
                    type: "manual",
                    message: "Signature is required",
                    shouldFocus: true,
                });
                return false;
            }
        
            // Check if the signature data length is greater than 13
            const signLength = Sign.trim().length; 

            const sizeInKB = DataUrlSize(Sign);

            if (signLength <= 13 && sizeInKB < 2) {
                setSignatureError(true);
                setError("signature_pad", {
                    type: "manual",
                    message: "Please provide a valid signature",
                    shouldFocus: true,
                });
                return false;
            }
        
            // If both conditions are satisfied, clear errors
            clearErrors("signature_pad");
            setSignatureError(false);
    
            // GTMDataLayer({
            //     clickEvent: 'slideclick',
            //     question: 'personal details',
            //     answer: '',
            // });
    
            setDisable(true);
            if(props.validationError === false){
              if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
                console.log("bank list", props.validationError)
                  props.slideChange(e.target.name);
                  formData.signature_data = Sign;
                  formData.selectedBanks = props.banks;
                  const selectedBanks = JSON.parse(localStorage.getItem("selectedBanks"));
                  if (selectedBanks) {
                    formData.bank = selectedBanks.map((bank) => bank.value);
                  }
                  formData.question_1 = "2";
                  localStorage.setItem('formData', JSON.stringify(formData));
                  localStorage.setItem('queryString', queryString);
                  let result = await saveDataIngestion(
                      visitorParameters.visitor_parameters,
                      formData,
                      'split_form_submit',
                      'pcp/TCG_L_D',
                      queryString,
                      visitorParameters.data
                  );
                  if(result){
                      props.setAddBank(true);
                  }
              }
            }
        }
  };

  return (
    <>
      {/* <div className={`sidesection py-2 pt-0 is-open`}> */}
      <div className={`sidesection py-2 pt-0 ${props.showSlide}`}>
        <div>
          <div className="form-style form-body p-4 pb-5">
            <h2 className="mb-3  title">Register Your Claim Online Now</h2>
            <div className="row">
              <div className="col-lg-2 mb-2">
                <SelectBox
                  className="form-select select1"
                  name="lstSalutation"
                  id="lstSalutation"
                  OptionValue={Salutation}
                  myRef={register({
                    required: "Select Title",
                    message: "Please Select Title",
                  })}
                  validationMsg={errors.lstSalutation?.message}
                  onChange={() => {
                    clearErrors(`lstSalutation`);
                  }}
                />
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <div className="input-group">
                    <TextField
                      type="text"
                      className="form-control form-control"
                      name="txtFName"
                      placeholder="First Name"
                      id="txtFName"
                      onkeypress={(e) => nameValidate(e)}
                      validation={register({
                        required: "Please Enter First Name",
                        minLength: {
                          value: 3,
                          message: "Please Enter Valid First Name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your Name is Invalid",
                        },
                      })}
                      onBlur={(e) => {
                        e.target.value = e.target.value.trim();
                      }}
                      validationMsg={errors.txtFName?.message}
                      onChange={() => {
                        clearErrors(`txtFName`);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <div className="input-group">
                    <TextField
                      type="text"
                      className="form-control form-control"
                      name="txtLName"
                      placeholder="Last Name"
                      id="txtLName"
                      onkeypress={(e) => nameValidate(e)}
                      validation={register({
                        required: "Please Enter Last Name",
                        minLength: {
                          value: 3,
                          message: "Please Enter Valid Last Name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your Name is Invalid",
                        },
                      })}
                      onBlur={(e) => {
                        e.target.value = e.target.value.trim();
                      }}
                      validationMsg={errors.txtLName?.message}
                      onChange={() => {
                        clearErrors(`txtLName`);
                      }}
                    />
                  </div>
                </div>
              </div>
              <input
                type="hidden"
                name="pCode_EntryType"
                id="pCode_EntryType"
                defaultValue={0}
              />
              <div className="col-lg-12 mt-3">
                <Postcode
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  scrollToEvent={props.scrollToPc}
                />
              </div>
            </div>
            <div id="date-birth">
              <Dob
                validation={register}
                validationMsg={errors}
                valids={valids}
                clearErrors={clearErrors}
                getValues={getValues}
                setError={setError}
              />
            </div>
            <div className=" mt-3">
              <ContactDetails
                validation={register}
                validationMsg={errors}
                valids={valids}
                clearErrors={clearErrors}
                getValues={getValues}
                setError={setError}
              />
            </div>
            <div>
              <SignatureV4
                setSign={setSign}
                FormSubmit={FormSubmit}
                disable={disable}
                setValue={setValue}
                validation={register}
                setError={setError}
                validationMsg={errors}
                clearErrors={clearErrors}
              />
              <Button
                type="submit"
                className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold"
                id="RegistBtn"
                onClick={(e) => FormSubmit(e)}
                buttonText="Submit"
                spanText="bi bi-arrow-right pe-none"
                name="formSubmit"
                //disabled={props.disable}
              />
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PersonalDetails;
