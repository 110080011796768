import React, { useReducer, useRef, useState } from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import sign_arrow from "../../../assets/img/arrow-green.png";
import DataUrlSize from "../../../Utility/DataUrlSize";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const SignatureV4 = (props) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const [sign, setSign] = useState('');

  const clearSign = () => {
    signPadRef.current.clear();
    props.clearErrors('signature_pad');
    setSign('');
    dispatch({
      type: "signPadChange",
      payload: { value: false },
    });
    return dispatch({
      type: "validSignature",
      payload: { value: false },
    });
  }
  const handleChange = () => {
    const signPad = signPadRef.current;
    const rpdata = signPad.toData();
    let signLength = 0;
    for (let i = 0; i < rpdata.length; i++) {
      signLength += Object.keys(rpdata[i]).length;
    }
    console.log('signLength', signLength);

    const sizeInKB = DataUrlSize(signPad.getTrimmedCanvas().toDataURL('image/png'));

    if (signLength > 13 && sizeInKB > 2) {
      setSign(signPadRef.current.getTrimmedCanvas().toDataURL('image/png'));
      return dispatch({
        type: "validSignature",
        payload: { value: false },
      });
    }
    else {
      return dispatch({
        type: "validSignature",
        payload: { value: true },
      });
    }

  }
  return (
    <>

      <div className="sign-sec" id="sign-div">
        <h5 className=" mb-1 mt-4" style={{ textAlign:"left" }}>
          By signing below I confirm that I was not told about the
          commission the lenders I have told you about would pay to the
          car dealerships for arranging my car finance.
        </h5>
        <div className="signbox">
        <h5>Sign below <i class="icodown"><img src={sign_arrow} /></i></h5>
          <TextField
            type="hidden"
            className="form-control"
            name="signature_data"
            id="signature_data"
            value={sign}
            validation={props.validation({})}
          ></TextField>
           <div className="border-div">
          {(deviceType === "mobile" ||
            deviceType === "tablet") && (
              <MobileView>
                <SignatureCanvas
                  canvasProps={{
                    className: "writing-line",
                    id: "canvas_signature",
                  }}

                  clearOnResize={false}
                  ref={signPadRef}
                  //{ ...props.validation('signature', { validate: validateSignature })}
                  onEnd={(e) => {
                    props.clearErrors('signature_pad');
                    if (!signPadRef.current.isEmpty()) {
                      // setButtonText("Proceed");
                      dispatch({
                        type: "signPadChange",
                        payload: { value: false },
                      });
                      dispatch({
                        type: "validSignature",
                        payload: { value: false },
                      });
                    };
                    handleChange(e)
                  }}
                />
              </MobileView>
            )}
          {deviceType === "browser" && (
            <BrowserView>
              <SignatureCanvas
                canvasProps={{
                  className: "writing-line",
                  id: "canvas_signature",

                }}
                clearOnResize={false}
                ref={signPadRef}
                //{ ...props.validation('signature', { validate: validateSignature })}
                onEnd={(e) => {
                  props.clearErrors('signature_pad');
                  if (!signPadRef.current.isEmpty()) {
                    dispatch({
                      type: "signPadChange",
                      payload: { value: false },
                    });
                    dispatch({
                      type: "validSignature",
                      payload: { value: false },
                    });
                  }
                  handleChange(e)
                }}
              />
            </BrowserView>
          )}
          <Button
          type="button"
          id=""
          name=""
          className="clearbutton mt-2 clear-sign"
          buttonText="Clear"
          onClick={clearSign}
        />
        </div>
        </div>
        {state.isCanvasEmpty && (
          <div
            className="sign_msg col-12 text-center sign_msg signature-validation error_msg"
            style={{
              backgroundColor: "red",
              borderRadius: "5px",
            }}
            id="signatures_required"
          >
            Signature is required!
          </div>
        )}
        {state.isValid && (
          <div
            className="sign_msg col-12 text-center sign_msg signature-validation error_msg"
            style={{ backgroundColor: "red" }}
            id="signatures_valid"
          >
            Draw valid signature!
          </div>
        )}

        {(props.validationMsg?.signature_pad) && (
          <div
            className="sign_msg col-12 text-center sign_msg signature-validation error_msg"
            style={{ backgroundColor: "red", borderRadius: "5px" }}
            id="signatures_required"
          >
            {props.validationMsg?.signature_pad ? props.validationMsg.signature_pad.message : ""}
          </div>
        )}
        
        <div>
          <div className="d-flex align-item-top sign-check mb-3 mt-3">
            <div className="pe-2">
              <div className="chicon">
                <i className="bi bi-lock" />
              </div>
            </div>
            <div>
              <label htmlFor="checkbox1" style={{ textAlign:"left" }}>
                Your personal information will be treated carefully in
                accordance with our Privacy Policy. We Claim Finder, is a
                trading style of The Claims Guys Legal, will contact you
                about claim opportunities using the contact details you
                provide. You can opt out of receiving communications from
                us at any time by sending an email to{" "}
                <a href="maiilto:dataprotection@theclaimsguyslegal.com">
                  dataprotection@theclaimsguyslegal.com
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default SignatureV4;